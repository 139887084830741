%links {
  text-decoration: none;
  color: $col-ruby;
  transition: color 0.3s;

  @media (any-pointer: fine) {
    &:hover {
      background-color: $col-bone;
      color: $col-ruby;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    }
  }
}

%link-invert {
  @media (any-pointer: fine) {
    &:hover {
      filter: invert(100%);
    }
  }
}
