$bg-img: url('../../static/CIMG0894.JPG');
$bg2-img: url('../../static/CIMG1165.JPG');
$fire-img: url('../../static/fire.gif');
$col-ruby: #9B111E;
$col-teal: #119B8E;
$col-sapphire: #0000FF;
$col-yellow: #FFFF00;
$col-bone: #E3dAC9;

%red-on-black-text {
  background: black;
  background-color: black;
  color: $col-ruby;
  font-style: italic;
}
