
@import './index.scss';

%padding-home {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

::selection {
  background-color: $col-bone;
  color: $col-ruby;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  background-attachment: fixed;
  background-image: $bg-img;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black; /* fallback color */
}

body, #root {
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  position: fixed;
}

.main {
  min-height: 100vh;
  visibility: visible;
  text-align: center;
  //position: fixed;
}

.main > * {
  padding: 10px;
  @include flex(1 100%);
}

.header {
  padding-bottom: 0;

  .container {
    @include flexbox();
    justify-content: space-around;
    align-items: center;
    color: $col-ruby;

    .canvas {
      position: fixed !important;
      max-height: 74px;

      Canvas {
        opacity: 0;
        touch-action: auto;
        animation: fade-in 1s ease .5s forwards;

        @keyframes fade-in {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }

    .left {
      @include flexbox();
      justify-content: space-evenly;
      align-items: center;
      .name {
        margin-right: .5rem;
        .link-style {
          @extend %links;
        }
      }
      .folio {
        background-color: black;
      }
    }

    .center {
      @include flexbox();
      .info3 {
        @extend %link-invert;
        background-image: $fire-img;
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        width: 10vw;
        height: 10vh;
        overflow: hidden;
        cursor: pointer;
      }
      .name3 {
        background-image: $fire-img;
        background-position: bottom;
        background-size: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        width: 20vw;
        height: 10vh;
        overflow: hidden;
      }
      .work3 {
        @extend %link-invert;
        background-image: $fire-img;
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        width: 10vw;
        height: 10vh;
        overflow: hidden;
        cursor: pointer;
      }
    }

    .right {
      @include flexbox();
      .avail {
        background-color: black;
      }
      .contact {
        margin-left: .5rem;
        visibility: visible;
      }
    }

    // Small screens
    @media all and (max-width: 900px) {
      flex-flow: wrap;
      .left {
        @include order(1);
        width: 100vw;
        justify-content: space-around;
        align-items: center;
        .name {
          visibility: visible;
        }
        .folio {
          visibility: visible;
        }
      }
      .center {
        @include order(2);
        width: 95vw;
        justify-content: space-around;
        align-items: center;
        .info3 {
          width: 25vw;
        }
        .name3 {
          height: 8vh;
          width: 60vw;
        }
        .work3 {
          width: 25vw;
        }
      }
      .right {
        @include order(3);
        width: 100vw;
        justify-content: space-around;
        align-items: center;
      }
    }
  }
}

.hero {
  visibility: visible;
  padding-top: .25rem;
  padding-bottom: .25rem;

  .container {
    @include flexbox();
    padding: .5rem;
    overflow: auto;
    background-image: $bg2-img;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;

    .home {
      @include flexbox();
      overflow: hidden;
      .text {
        @extend %red-on-black-text;
      }
    }

    .info {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 10rem;
      padding-right: 10rem;
      display: block;

      .text {
        @extend %red-on-black-text;
      }

      .image {
        max-height: 300px;
        max-width: 400px;
      }

      .link-style {
        @extend %links;
      }
    }

    .project {
      max-height: 60vh;
      width: 45vh;
      margin-bottom: 1vh;
      margin-top: .5rem;
      margin-left: 1rem;
      margin-right: 1rem;
      .card {
        @extend %red-on-black-text;
        padding-bottom: 1vh;

        .image {
          aspect-ratio: 4/3;
          width: 45vh;
          object-fit: cover;
          cursor: pointer;
        }

        .body {
          .title {
            margin-top: 0;
          }
        }
      }
    }

    .detail {
      @include flexbox();

      .image {
        max-height: 70vh;
        max-width: 100vw;
      }
      .title {
        @extend %red-on-black-text;
        writing-mode: vertical-rl;
        text-orientation: upright;
        margin-right: 0;
      }
      .body {
        @extend %red-on-black-text;
        margin: auto;
        padding-bottom: 1rem;
      }
      .git-link {
        @extend %links;
      }
    }

    .credit {
      &__text {
        @extend %red-on-black-text;
        padding-bottom: 1rem;

        .link {
          @extend %links;
        }
      }
    }

    .contact {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      z-index: 11;
      .form {
        @include flexbox();
        flex-direction: column;
      }

      .text {
        @extend %red-on-black-text;
      }

      .name-area {
        color: $col-ruby;
        background-color: $col-bone;
        border-color: black;
      }

      .email-area {
        color: $col-ruby;
        background-color: $col-bone;
        border-color: black;
      }

      .message-area {
        height: 30vh;
        color: $col-ruby;
        background-color: $col-bone;
        border-color: black;
      }

      .submit-button {
        background-color: black;
        color: $col-ruby;
        border-color: $col-ruby;
        cursor: pointer;
      }
    }

    // Small screens
    @media all and (max-width: 900px) {
      justify-content: normal;
      flex-direction: column;
      flex-wrap: nowrap;
      overflow-x: hidden;

      .project {
        .card {
          .image {
            visibility: visible;
          }
        }
      }

      .detail {
        flex-direction: column;

        .image {
          max-height: inherit;
          max-width: 100%;
          height: inherit;

        }

        .title {
          @extend %red-on-black-text;
          writing-mode: horizontal-tb;
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .info {
        padding: .5rem;
        .image {
          max-height: 200px;
          max-width: 300px;
          float: right;
        }
      }

      .contact {
        font-size: 18px;
        select,
        textarea,
        input {
          font-size: 20px;
        }
      }
    }
  }
}

.footer {
  .container {
    @include flexbox();
    justify-content: space-around;
    align-items: center;
    color: $col-ruby;
    overflow: hidden;

    .left {
      @include flexbox();
      .clock {
        background-color: black;
      }
    }
    .center {
      @include flexbox();
    }
    .right {
      @include flexbox();
      .credit {
        background-color: black;

        .link-style {
          @extend %links;
        }
      }
    }

    // Small screens
    @media all and (max-width: 900px) {
      flex-flow: wrap;
      .left {
        @include order(2);
        width: 100vw;
        justify-content: space-around;
        align-items: center;

        .clock {
          margin-bottom: 1px;
        }
      }

      .center {
        @include order(1);
        width: 100vw;
        justify-content: space-around;
        align-items: center;
      }

      .right {
        @include order(3);
        width: 100vw;
        justify-content: space-around;
        align-items: center;
      }
    }
  }

  // Small screens
  @media all and (max-width: 900px) {
    padding-top: 0px;
  }
}
