
@import './variables.scss';

.c-button {
  display: inline-block;
  background: black;
  text-transform: uppercase;
  text-decoration: none;
  padding: 5px 8px;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 2px;
  border: 2px solid $col-ruby;
  //border-radius: 45px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    @media (any-pointer: fine) {
      background-color: $col-bone;
      font-weight: 500;
    }
  }

  .c-link {
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &__inner {
      display: flex;
      transform: translateY(0);
      transition: transform 0.3s ease-out;
      transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      margin-right: 2px;

      > span {
        /* white-space: nowrap; */
        a {
          text-decoration: none;
          color: $col-ruby;
        }
        Link {
          text-decoration: none;
          color: $col-ruby;
        }
      }
    }

    &__animated {
      /* white-space: nowrap; */
      position: absolute;
      top: 100%;
      display: none;
      text-decoration: none;
      //font-family: $font-bookish;

      @media (any-pointer: fine) {
        display: inline-block;
      }
    }
  }

  &:hover {
    @media (any-pointer: fine) {
      .c-link__inner {
        transform: translateY(-100%);
      }
    }
  }
}
